/* =======================
7.0 Services Page    
 ========================*/

/* 3.2 service-page */

.service-page {
	.service-section-s2 {
		padding-bottom: 50px;
	}

	//   exprience-service

	.exprience-service {
		.exprience-service-wrap {
			background: $white;
			padding: 0;

			.exprience-service-item {
				border-color: rgba(0, 0, 0, 0.10);

				ul li {
					b {
						color: $dark-gray;
					}

					&:first-child::before {
						background: rgba(0, 0, 0, 0.10);
						;
					}

					span {
						color: $dark-gray;
					}
				}

				&:hover {
					ul li {
						i svg {
							stroke: $dark-gray;
						}
					}
				}
			}
		}
	}


}


// service-single-page

.service-single-page {

	.service-single-wrap {
		h2 {
			font-size: 80px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 20px;

			@media(max-width:991px) {
				font-size: 60px;
			}
			@media(max-width:575px) {
				font-size: 40px;
			}
		}

		@media(max-width:575px) {
			text-align: center;
		}

		.inner-img {
			padding: 60px 0;

			img {
				margin-bottom: 10px;
				width: 100%;
			}
		}
	}

	.event-section {
		padding: 60px 0 100px;

		@media(max-width:1199px) {
			padding: 80px 0;
		}

		@media(max-width:991px) {
			padding-bottom: 0;
			padding-top: 0;
		}

		.event-items {

			@media(max-width:991px) {
				text-align: center;
			}

			h3 {
				color: $dark-gray;
			}

			.event-item {
				h4 {
					color: $dark-gray;
				}

				span {
					color: $dark-gray;
				}
			}
		}

		.event-left-img {
			position: relative;
			width: 360%;

			@media(max-width:991px) {
				width: 100%;
				margin-bottom: 0;
			}
		}
	}

	.blog-sidebar {
		@media(max-width:991px) {
			padding-bottom: 60px;
		}
	}
}