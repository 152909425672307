/* =======================
10.0 Pricing Page
 ========================*/

/* 10.1 about-me-page */

.pricing-section {
	padding-bottom: 90px;

	@include media-query(991px) {
		padding-bottom: 60px;
	}
	@include media-query(767px) {
		padding-bottom: 40px;
	}

	.pricing-items {
		.pricing-item {
			text-align: center;
			padding: 60px;
			box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);
			margin-bottom: 30px;

			@include media-query(1199px) {
				padding: 40px 30px;
			}

			h2 {
				font-size: 18px;
				font-family: $base-font;
				margin-bottom: 20px;
				display: inline-block;
				padding: 12px 20px;
				background: #FDF9F6;
				border-radius: 30px;
			}

			.btn-style-2.active {
				background: $theme-primary-color;
				color: $white;
			}

			.price-price {
				margin-bottom: 30px;
				h3 {
					font-size: 50px;

					span {
						font-size: 18px;
					}
				}
			}

			ul {
				list-style: none;
				margin-bottom: 30px;

				li {
					padding: 20px 0;
					font-size: 18px;
					border-bottom: 1px solid #f2f2f2;

					@include media-query(1199px) {
						font-size: 16px;
					}

					&:last-child {
						border-bottom: 0;
						padding-bottom: 0;
					}

					&:first-child {
						padding-top: 0;
					}
				}
			}
		}
	}

}