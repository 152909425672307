.section-title,
.section-title-s2,
.section-title-s3 {
	margin-bottom: 60px;

	@include media-query(767px) {
		margin-bottom: 40px;
		text-align: center;
	}

	@include media-query(575px) {
		margin-bottom: 20px;
		text-align: center;
	}

	span {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 29px;
		letter-spacing: 8px;
		display: block;
		color: $white;

		@media(max-width:767px) {
			font-size: 15px;
			letter-spacing: 4px;
		}
	}

	h2 {
		font-size: 80px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		color: $white;

		@include media-query(1199px) {
			font-size: 70px;
		}	
		@include media-query(991px) {
			font-size: 60px;
		}	

		@include media-query(767px) {
			font-size: 50px;
			line-height: 40px;
		}

		@include media-query(575px) {
			font-size: 35px;
			margin-top: 0;
		}

	}

	p {
		font-size: 18px;
	}
}

.section-title-s2 {
	text-align: center;
}

// section-title-s3

.section-title-s3 {

	@media(max-width:991px){
        text-align: center;
	}
	span {
		color: $body-color;
	}

	h2 {
		color: $body-color;
	}
}