.btn-style-1,
.btn-style-2 {
	background: transparent;
	color: $white;
	display: inline-block;
	font-weight: 500;
	padding: 15px 45px;
	border: 0;
	z-index: 1;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	font-family: $heading-font;
	border: 1px solid $theme-primary-color;
	border-radius: 40px;
	position: relative;
	overflow: hidden;

	&:before {
		position: absolute;
		top: 0px;
		right: 0px;
		height: 100%;
		width: 0px;
		z-index: -1;
		content: "";
		border-radius: 100px;
		background: $theme-primary-color;
		transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
	}

	&.btn-style-1-s3 {
		background: $white;
		color: $theme-primary-color;

		&:after {
			border: 1px solid $white;
		}
	}

	&.btn-style-1-s4 {
		background: $theme-primary-color;
		color: $white;

		&:after {
			border: 1px solid $theme-primary-color;
		}
	}

	&:hover,
	&:focus,
	&:active {
		&:before {
			left: 0%;
			right: auto;
			width: 100%;
		}
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
	}
}

.fancy-btn {
	color: $white;
	text-align: center;
	font-family: $heading-font;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 157%;
	letter-spacing: -0.32px;
	width: 126.921px;
	height: 134.486px;
	border: 1px solid $white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
	animation: morphing 10s infinite;
	margin-top: 30px;
	background: none;
	align-items: center;

	@media (max-width: 991px) {
		margin: 0 auto;
		margin-top: 30px;
	}

	i {
		margin-top: 10px;
	}

	&:hover {
		color: $white;
	}
}

.btn-style-2 {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 29px;
	color: $dark-gray;
	font-family: $base-font;
}