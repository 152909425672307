/* =======================
25. Blog Single Page
 ========================*/

/* blog-single-area */

.blog-single-area {
   .blog-single-wrap {
      @media(max-width:575px) {
         text-align: center;
      }
      .blog-single-text {
         @media(max-width:575px) {
            text-align: center;
         }
         .blog-single-text-top {
            text-align: center;
            margin-top: 50px;
            margin-bottom: 30px;

            span {
               font-size: 16px;
               font-style: normal;
               font-weight: 400;
               line-height: 29px;
            }

            h2 {
               font-size: 60px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;

               @media(max-width:991px) {
                  font-size: 45px;
               }

               @media(max-width:575px) {
                  font-size: 30px;
               }

            }
         }

         blockquote {
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: $heading-font;
            text-align: center;
            padding: 100px 0;
            margin-bottom: 0;

            @media(max-width:991px) {
               font-size: 45px;
               padding: 70px 0;
            }

            @media(max-width:575px) {
               font-size: 30px;
               padding: 30px 0;
            }
         }
      }

      .blog-single-alternative {
         padding: 50px 0;
         margin-bottom: 40px;

         @media(max-width:575px) {
            margin-bottom: 0px;
            text-align: center;
         }

         h2 {
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            margin-bottom: 30px;

            @media(max-width:991px) {
               font-size: 45px;
            }

            @media(max-width:575px) {
               font-size: 30px;
            }
         }

         p {
            margin-bottom: 80px;
         }

         img {
            width: 100%;
         }
      }

      .tag-share-wrap {
         padding: 80px 0 100px;

         @media(max-width:991px) {
            padding: 30px 0 50px;
         }

         .tag-share {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @media(max-width:767px) {
               flex-wrap: wrap;
            }

            h3 {
               font-size: 40px;
               font-style: normal;
               font-weight: 400;
               line-height: 50px;
               margin-right: 20px;

               @media(max-width:767px) {
                  font-size: 20px;
                  margin-right: 10px;
               }
            }

            ul {
               display: flex;

               @media(max-width:767px) {
                  flex-wrap: wrap;
               }

               li {
                  &+li {
                     margin-left: 25px;

                     @media(max-width:767px) {
                        margin-left: 5px;
                     }
                  }

                  a {
                     color: $dark-gray;

                     @media(max-width:575px) {
                        font-size: 14px;
                     }

                     &:hover {
                        color: $theme-primary-color;
                     }
                  }
               }
            }
         }
      }

      .author-box {
         padding: 30px;
         background: #FDF9F6;
         overflow: hidden;

         @media(max-width:991px) {
            text-align: center;
            padding: 15px;
         }

         .author-avatar {
            float: left;
            overflow: hidden;
            margin-right: 40px;

            @media(max-width:991px) {
               float: none;
               margin-right: 0;
            }

            img {
               width: 100%;
            }
         }

         .author-content {
            padding-right: 30px;
            overflow: hidden;
            margin-top: 30px;

            @media(max-width:991px) {
              padding: 0;
            }

            p{
               @media(max-width:575px) {
                  font-size: 15px;
                }
            }


            .author-name {
               font-size: 40px;
               font-style: normal;
               font-weight: 400;
               line-height: 50px;
               font-family: $heading-font;
               color: $dark-gray;
               display: block;
               margin-bottom: 10px;

               @media(max-width:991px) {
                  font-size: 30px;
               }
            }

            ul {
               display: flex;
               justify-content: flex-end;

               @media(max-width:991px) {
                  justify-content: center;
               }

               li {
                  &+li {
                     margin-left: 25px;
                  }

                  a {
                     color: $dark-gray;

                     &:hover {
                        color: $theme-primary-color;
                     }
                  }
               }
            }
         }

      }

      /*** comment-respond ***/
      .comment-respond {
         margin-top: 70px;
         max-width: 856px;

         .comment-reply-title {
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @include media-query(991px) {
               font-size: 40px;
            }
            @include media-query(575px) {
               font-size: 30px;
            }
         }

         p {
            margin-bottom: 50px;
         }

         form input,
         form textarea {
            background: #FDF9F6;
            width: 100%;
            height: 55px;
            border: 0;
            padding: 6px 15px;
            margin-bottom: 15px;
            outline: 0;
            border-radius: 0px;
            box-shadow: none;
            @include transition-time(0.3s);

            &:focus {
               box-shadow: none;
               border-color: $theme-primary-color;
            }

            @include media-query(991px) {
               height: 50px;
            }
         }

         form textarea {
            height: 220px;
            padding: 15px;

            @include media-query(991px) {
               height: 150px;
            }
         }

         .form-inputs,
         .form-textarea {
            overflow: hidden;

            label {
               font-size: 16px;
               font-style: normal;
               font-weight: 400;
               line-height: 29px;
               margin-bottom: 10px;
            }
         }

         .form-inputs>.form-field:nth-child(1) {
            width: 49%;
            float: left;

            @include media-query(767px) {
               width: 100%;
               float: none;
            }
         }

         .form-inputs>.form-field:nth-child(2) {
            width: 49%;
            float: right;

            @include media-query(767px) {
               width: 100%;
               float: none;
            }
         }

         .form-submit input {
            max-width: 180px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
            margin-bottom: 0;
            border: 1px solid $theme-primary-color;
            outline: 0;
            background: transparent;
            border-radius: 30px;
            margin-top: 30px;
         }

         .form-submit input:hover {
            background-color: darken($theme-primary-color, 9%);
            color: $white;
         }
      }
   }

   &.with-sidebar {
      .blog-single-wrap {
         .blog-single-text {
            blockquote {
               font-size: 45px;
               padding: 70px 0;

               @media(max-width:991px) {
                  font-size: 45px;
               }
   
               @media(max-width:575px) {
                  font-size: 30px;
                  padding: 30px 0;
               }
            }
         }

         .blog-single-alternative h2 {
            font-size: 45px;

            @media(max-width:575px) {
               font-size: 30px;
            }
         }
      }
   }
}