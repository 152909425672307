// page-title

.page-title {
	background: #FDF9F6;
	min-height: 300px;
	position: relative;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	z-index: 1;

	@media(max-width: 767px) {
		min-height: 250px;
	}
	@media(max-width: 575px) {
		min-height: 150px;
	}

	.breadcumb-wrap {
		padding-bottom: 30px;

		@media(max-width: 575px) {
			text-align: center;
		}

		h2 {
			font-size: 80px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			color: $dark-gray;
			margin-bottom: 25px;

			@media(max-width: 767px) {
				font-size: 30px;
				line-height: 55px;
				margin-bottom: 10px;
			}
		}

		ol {
			padding-left: 0;
			margin-bottom: 0;
			display: flex;
			justify-content: flex-end;

			@media(max-width: 575px) {
				justify-content: center;
			}

			li {
				display: inline-block;
				padding: 0px 15px;
				color: $text-color;
				position: relative;
				font-size: 20px;

				@media(max-width:767px) {
					font-size: 18px;
				}

				@media(max-width:575px) {
					font-size: 16px;
					padding: 0 10px;

					&:first-child {
						padding-left: 0;
					}
				}

				&:after {
					content: "/";
					position: absolute;
					right: -10px;
					top: 50%;
					transform: translateY(-50%);
				}

				&:last-child {
					span {
						color: #cbd4fd;
					}

					&:after {
						display: none;
					}
				}

				a {
					color: $text-color;
					font-size: 20px;
					transition: all .3s;

					@media(max-width:767px) {
						font-size: 18px;
					}

					@media(max-width:575px) {
						font-size: 16px;
					}

					&:hover {
						color: $theme-primary-color;
					}
				}
			}
		}
	}
}